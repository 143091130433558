import CheckIcon from '@mui/icons-material/Check';
import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';

import Container from '@/components/Container';

import { plans } from '@/constant/LandingPage/plans';

const Pricing: React.FC = () => {
  return (
    <>
      <Container>
        <Grid
          container
          spacing={3}
          justifyContent='center'
          sx={{ maxWidth: '1200px', margin: '0 auto', width: '100%' }}
        >
          {plans.map((plan, index) => (
            <Grid item xs={12} md={6} lg={3} key={index}>
              <Paper
                elevation={0}
                sx={{
                  p: { xs: 2, md: 3 },
                  borderRadius: '16px',
                  backgroundColor: 'rgb(243, 244, 246)',
                  '&:hover': {
                    backgroundColor: 'rgb(229, 231, 235)',
                  },
                }}
                className='flex flex-col justify-between w-full h-full bg-gray-100'
              >
                <Typography className=' text-gray-800 sm: text-lg md:text-xl lg:text-2xl'>
                  {plan.name + ' Package'}
                </Typography>
                <Typography className='text-lg sm:text-xl lg:text-2xl xl:text-3xl text-gold-500'>
                  ${plan.price}.00{index !== 0 && ' / one-time payment'}
                </Typography>
                <Box>
                  {plan.features.map((feature, idx) => (
                    <Box
                      key={idx}
                      display='flex'
                      alignItems='center'
                      mb={0}
                      className='text-base text-gray-600 sm:text-lg'
                    >
                      <CheckIcon className='mr-2 text-yellow-500' />
                      <Typography
                        // variant='body1'
                        className='text-sm sm:text-base py-0 my-0'
                      >
                        {feature}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default Pricing;
