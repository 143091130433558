import { SignUpButton } from '@clerk/clerk-react';
import React from 'react';

import Container from '@/components/Container';

import { underRepair } from '@/constant/config';

interface CallToActionProps {
  handleUnderRepair: () => void;
}

const CallToAction: React.FC<CallToActionProps> = ({ handleUnderRepair }) => {
  return (
    <Container>
      <div className='flex flex-wrap items-center justify-between w-full max-w-4xl gap-5 mx-auto text-white bg-gold-600 px-7 py-7 lg:px-12 lg:py-12 lg:flex-nowrap rounded-xl'>
        <div className='flex-grow text-center lg:text-left'>
          <h2 className='text-2xl font-medium lg:text-3xl'>
            Transform Your Online Identity with AI Generated Profile Pictures!
          </h2>
          <p className='mt-2 font-medium text-white text-opacity-90 lg:text-xl sm:block hidden'>
            Stand out in the digital crowd with a unique, AI generated portrait.
            Our cutting-edge technology turns your selfies into stunning profile
            pictures in seconds, giving your social media presence an instant
            upgrade.
          </p>
        </div>
        {underRepair ? (
          <div className='flex-shrink-0 w-full text-center lg:w-auto'>
            <a
              className='inline-block py-3 mx-auto text-lg font-medium text-center text-gold-600 bg-white rounded-md px-7 lg:px-10 lg:py-5 '
              onClick={handleUnderRepair}
            >
              Start Free Trial
            </a>
          </div>
        ) : (
          <SignUpButton mode='modal'>
            <div className='flex-shrink-0 w-full text-center lg:w-auto'>
              <a className='inline-block py-3 mx-auto text-lg font-medium text-center text-gold-600 bg-white rounded-md px-7 lg:px-10 lg:py-5 '>
                Start Free Trial
              </a>
            </div>
          </SignUpButton>
        )}
      </div>
    </Container>
  );
};

export default CallToAction;
