import React from 'react';

import Container from '@/components/Container';

interface SectionTitleProps {
  align?: 'left' | 'center';
  pretitle?: string;
  title?: string;
  children?: React.ReactNode;
  id?: string;
}

const SectionTitle: React.FC<SectionTitleProps> = (props) => {
  return (
    <Container
      className={`flex w-full flex-col mt-3 ${
        props.align === 'left' ? '' : 'items-center justify-center text-center'
      }`}
    >
      {props.pretitle && (
        <div
          className='text-lg sm:text-xl md:text-2xl font-bold tracking-wider text-gold-500 uppercase'
          id={props.id}
        >
          {props.pretitle}
        </div>
      )}
      {props.title && (
        <h2 className='max-w-2xl mt-3 text-xl sm:text-2xl md:text-3xl font-bold leading-snug tracking-tight text-gray-800 lg:leading-tight lg:text-4xl dark:text-white'>
          {props.title}
        </h2>
      )}
      {props.children && (
        <p className='max-w-2xl py-3 text-lg leading-normal text-gray-500 lg:text-xl xl:text-xl dark:text-gray-300 sm:block hidden'>
          {props.children}
        </p>
      )}
    </Container>
  );
};

export default SectionTitle;
