import { siteConfig } from '@/constant/config';

export const plans = [
  {
    name: 'Free',
    features: [
      `${siteConfig.initialNumberOfSketchesRemaining} sketches (credits)`,
      `Select up to ${siteConfig.initialNumberOfSketchesRemaining / 4} styles & colors (4 sketches per style)`,
      'Generated with SketchMe.App Logo',
    ],
    price: 0,
  },
  {
    name: 'Small',
    features: [
      `${siteConfig.stripePlans.Small.maxSketches} sketches (credits)`,
      `Select up to ${siteConfig.stripePlans.Small.maxSketches / 4} styles & colors (4 sketches per style)`,
      'Remove SketchMe.App Logo from generated images',
    ],
    price: siteConfig.stripePlans.Small.price / 100,
  },
  {
    name: 'Basic',
    features: [
      `${siteConfig.stripePlans.Basic.maxSketches} sketches (credits)`,
      `Select up to ${siteConfig.stripePlans.Basic.maxSketches / 4} styles & colors (4 sketches per style)`,
      'Remove SketchMe.App Logo from generated images',
    ],
    price: siteConfig.stripePlans.Basic.price / 100,
  },
  {
    name: 'Pro',
    features: [
      `${siteConfig.stripePlans.Pro.maxSketches} sketches (credits)`,
      `Select up to ${siteConfig.stripePlans.Pro.maxSketches / 4} styles & colors (4 sketches per style)`,
      'Remove SketchMe.App Logo from generated images',
    ],
    price: siteConfig.stripePlans.Pro.price / 100,
  },
];
