export const exampleSketches = [
  {
    original: '/images/examples/example-1.jpg',
    thumbnail: '/images/examples/example-1.jpg',
  },
  {
    original: '/images/examples/example-2.jpg',
    thumbnail: '/images/examples/example-2.jpg',
  },
  {
    original: '/images/examples/example-3.jpg',
    thumbnail: '/images/examples/example-3.jpg',
  },
  {
    original: '/images/examples/example-4.jpg',
    thumbnail: '/images/examples/example-4.jpg',
  },
  {
    original: '/images/examples/example-5.jpg',
    thumbnail: '/images/examples/example-5.jpg',
  },
  {
    original: '/images/examples/example-6.jpg',
    thumbnail: '/images/examples/example-6.jpg',
  },
  {
    original: '/images/examples/example-7.jpg',
    thumbnail: '/images/examples/example-7.jpg',
  },
  {
    original: '/images/examples/example-8.jpg',
    thumbnail: '/images/examples/example-8.jpg',
  },
  {
    original: '/images/examples/example-9.jpg',
    thumbnail: '/images/examples/example-9.jpg',
  },
  {
    original: '/images/examples/example-10.jpg',
    thumbnail: '/images/examples/example-10.jpg',
  },
  {
    original: '/images/examples/example-11.jpg',
    thumbnail: '/images/examples/example-11.jpg',
  },
  {
    original: '/images/examples/example-12.jpg',
    thumbnail: '/images/examples/example-12.jpg',
  },
  {
    original: '/images/examples/example-13.jpg',
    thumbnail: '/images/examples/example-13.jpg',
  },
  {
    original: '/images/examples/example-14.jpg',
    thumbnail: '/images/examples/example-14.jpg',
  },
];
