import Image from 'next/image';
import React from 'react';
import { ComponentType } from 'react';

import Container from '@/components/Container';

interface BulletPointsAndImageProps {
  data: {
    title: string;
    desc: string;
    image: string;
    imgPos: 'left' | 'right';
    bullets: {
      title: string;
      desc: string;
      icon: ComponentType<{ className?: string }>;
    }[];
  };
}

const BulletPointsAndImage: React.FC<BulletPointsAndImageProps> = (props) => {
  const { data } = props;

  return (
    <>
      <Container className='flex flex-wrap lg:gap-10 lg:flex-nowrap'>
        <div
          className={`flex items-center justify-center w-full lg:w-1/2 ${
            props.data.imgPos === 'right' ? 'lg:order-1' : ''
          }`}
        >
          <div>
            <Image
              src={data.image}
              width='1024'
              height={524}
              alt='Services'
              className='object-cover'
              placeholder='blur'
              blurDataURL={data.image}
              loading='lazy'
            />
          </div>
        </div>
        <div
          className={`flex flex-wrap items-center w-full lg:w-1/2 ${
            data.imgPos === 'right' ? 'lg:justify-end' : ''
          }`}
        >
          <div>
            <div className='flex flex-col w-full mt-0 md:mt-4'>
              <h3 className='max-w-2xl mt-3 text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold leading-snug tracking-tight text-gray-800 lg:leading-tight dark:text-white'>
                {data.title}
              </h3>
              <p className='max-w-2xl py-4 text-md lg:text-lg xl:text-xl leading-normal text-gray-500 dark:text-gray-300'>
                {data.desc}
              </p>
            </div>
            <div className='w-full mt-1 sm:mt-3 md:mt-5'>
              {data.bullets.map((item, index) => (
                <BulletPoint key={index} title={item.title} icon={item.icon}>
                  {item.desc}
                </BulletPoint>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

interface BulletPointProps {
  title: string;
  icon: ComponentType<{ className?: string }>;
  children: React.ReactNode;
}

function BulletPoint(props: BulletPointProps) {
  return (
    <>
      <div className='flex items-center mt-2 md:mt-4 lg:mt-6 xl:mt-8 space-x-3'>
        <div className='flex items-center justify-center flex-shrink-0 mt-1 bg-gold-500 rounded-md w-8 sm:w-11 h-8 sm:h-11'>
          <props.icon className='w-5 sm:w-7 h-5 sm:h-7 text-gold-50' />
        </div>
        <div>
          <h4 className='text-md sm:text-xl font-medium text-gray-800 dark:text-gray-200'>
            {props.title}
          </h4>
          <p className='mt-1 text-gray-500 dark:text-gray-400 sm:block hidden'>
            {props.children}
          </p>
        </div>
      </div>
    </>
  );
}

export default BulletPointsAndImage;
