import Image from 'next/image';
import React from 'react';

import Container from '@/components/Container';

interface StyleBoxProps {
  image: string;
  title: string;
  description: string;
}

const ArtStyleSelector = () => {
  const artStyles = [
    {
      image: '/images/pencilsketch.jpg',
      title: 'Pencil Sketch',
      description:
        'A style that mimics the look of a pencil sketch, with fine lines and shading.',
    },
    {
      image: '/images/flatdesign.jpg',
      title: 'Flat Design',
      description:
        'A minimalistic style characterized by simple shapes, bold colors, and flat elements.',
    },
    {
      image: '/images/pixaranimation.jpg',
      title: 'Pixar Animation',
      description:
        'A style inspired by the iconic 3D animation of Pixar movies.',
    },
    {
      image: '/images/pixelart.jpg',
      title: 'Pixel Art',
      description:
        'A retro style that uses small, square-shaped pixels, like CryptoPunks NFTs.',
    },
    {
      image: '/images/caricature.jpg',
      title: 'Caricature',
      description:
        'A style that exaggerates certain features of a subject for comedic or satirical effect.',
    },
    {
      image: '/images/goghstyle.jpg',
      title: 'Gogh Style',
      description:
        'A style inspired by the post-impressionist paintings of Vincent van Gogh.',
    },
  ];

  return (
    <Container>
      <div className='grid gap-8 grid-cols-2 lg:grid-cols-3 w-full md:w-11/12 lg:w-5/6 mx-auto'>
        {artStyles.map((style, index) => (
          <StyleBox
            key={index}
            image={style.image}
            title={style.title}
            description={style.description}
          />
        ))}
      </div>
    </Container>
  );
};

function StyleBox({ image, title, description }: StyleBoxProps) {
  return (
    <div className='flex flex-col w-full bg-gray-100 rounded-2xl overflow-hidden dark:bg-trueGray-700'>
      <div className='relative w-full h-24 sm:h-56 xl:h-64'>
        <Image
          src={image}
          fill
          style={{ objectFit: 'cover' }}
          alt={title}
          sizes='(max-width: 1024px) 50vw, 33vw'
          priority={false}
        />
      </div>
      <div className='px-2 sm:px-4 lg:px-8 py-2'>
        <h3 className='text-sm sm:text-xl font-semibold mb-0'>{title}</h3>
        <p className='text-sm lg:text-base leading-normal sm:block hidden'>
          {description}
        </p>
      </div>
    </div>
  );
}

export default ArtStyleSelector;
