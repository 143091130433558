'use client';
import Head from 'next/head';
import '@/lib/env';

import useLandingPage from '@/lib/hooks/useLandingPage';

import BulletPointsAndImage from '@/app/components/LandingPage/BulletPointsAndImage';
import CallToAction from '@/app/components/LandingPage/CallToAction';
import ExampleSketches from '@/app/components/LandingPage/ExampleSketches';
import Faq from '@/app/components/LandingPage/FAQ';
import Footer from '@/app/components/LandingPage/Footer';
import Hero from '@/app/components/LandingPage/Hero';
import Navbar from '@/app/components/LandingPage/NavigationBar';
import Pricing from '@/app/components/LandingPage/Pricing';
import SectionTitle from '@/app/components/LandingPage/SectionTitle';
import ArtStyleSelector from '@/app/components/LandingPage/Styles';
import { howItWorksData } from '@/constant/LandingPage/howItWorks';

export default function LandingPage() {
  const handleUnderRepair = useLandingPage();

  return (
    <main>
      <Head>
        <title>SketchMe.App</title>
      </Head>
      <Navbar handleUnderRepair={handleUnderRepair} />
      <Hero handleUnderRepair={handleUnderRepair} />
      <SectionTitle
        pretitle='Styles'
        title='We offer a diverse selection of Styles for your illustration.'
        id='styles'
      />
      <ArtStyleSelector />
      <SectionTitle
        pretitle='Example Sketches'
        title='Our AI Artist takes Creativity to a whole new level.'
        id='examples'
      />
      <ExampleSketches />
      <SectionTitle
        pretitle='How it Works'
        title="Upload few Selfies, select your style, and that's it! You're ready!"
        id='how-it-works'
      />
      <BulletPointsAndImage data={howItWorksData} />
      <SectionTitle
        pretitle='Pricing'
        title='Get a personal illustration for 10x less than commissioning an illustrator.'
        id='pricing'
      >
        All packages are one-time payments, giving your account the credit to
        generate sketches. You can add credits any time.
      </SectionTitle>
      <Pricing />
      <SectionTitle
        pretitle='FAQ'
        title='Frequently Asked Questions'
        id='faq'
      />
      <Faq />
      <CallToAction handleUnderRepair={handleUnderRepair} />

      <Footer />
    </main>
  );
}
