import { GrCheckboxSelected } from 'react-icons/gr';
import { MdNotStarted, MdOutlinePortrait } from 'react-icons/md';

export const howItWorksData = {
  title: '3 Simple Steps',
  desc: 'Follow the steps below to create your Social Media Profile Picture.',
  image: '/images/howItWorks.png',
  imgPos: 'left' as const,
  bullets: [
    {
      title: 'Upload your Selfies',
      desc: 'Upload up to 5 selfie photos of yourself. Photos should contain your face mainly.',
      icon: MdOutlinePortrait,
    },
    {
      title: 'Select Style & Color',
      desc: 'Select your preferred style of illustration. Also select your theme color.',
      icon: GrCheckboxSelected,
    },
    {
      title: 'Hit the "Generate" button!',
      desc: 'Hit the "Generate" button and 8 images will apear at the "Generated Sketch" gallery. 4 images at a time, within a few minutes.',
      icon: MdNotStarted,
    },
  ],
};
