// src/lib/hooks/useLandingPage.ts
import { useUser } from '@clerk/clerk-react';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

import { underRepair } from '@/constant/config';

const useLandingPage = () => {
  const { isSignedIn } = useUser();
  const router = useRouter();

  useEffect(() => {
    if (isSignedIn) {
      router.push('/dashboard');
    }
  }, [isSignedIn, router]);

  const redirectToUnderRepair = () => {
    if (underRepair) {
      router.push('/under-repair');
    }
  };

  return redirectToUnderRepair;
};

export default useLandingPage;
