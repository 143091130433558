export const faqdata = [
  {
    question: 'How will the illustrations be created?',
    answer:
      'Once you upload your selfies and select the preferred style, our proprietary Generative AI model will automatically create the illustration within a couple of minutes.',
  },
  {
    question: 'Are the sketches available for commercial use?',
    answer:
      'Yes, the generated sketches may be used for both non-commercial and commercial purposes.',
  },
  {
    question: 'Can we ask for minor adjustments on our sketches?',
    answer:
      'Unfortunately not, but feel free to post your request on the contact form.',
  },
];
